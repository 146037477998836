import React from 'react'
// 
import scrolldownicon from '../assets/scroll-down-icon.png'
import deliverybox from '../assets/deliverybox.png'
import stopwatch from '../assets/stopwatch.png'
import security from '../assets/security.png'
import convenience from '../assets/convenience.png'
import navigation from '../assets/navigation.png'



function Deliverypage() {
    return (
        <>
            <section className='contacttopsection flexcenter'>
                <div className="container-fluid  p-0">
                    <div className="container flexcenter">
                        <div className="row w-100">
                            <div className="col-md-6 flexcenter" data-aos="fade-right" data-aos-easing="ease-in-sine"
                                data-aos-duration="500">
                                <img className='img-fluid' width="600px" src={deliverybox} alt="" />
                            </div>
                            <div className="col-md-6 flexcenter" data-aos="fade-left" data-aos-easing="ease-in-sine"
                                data-aos-duration="500">
                                <div className="container">
                                    <h1>Delivery Services</h1>
                                    <p>We prioritize on-time delivery to meet your deadlines, offering reliable and secure package handling, a convenient booking process with flexible options, and advanced tracking systems for real-time updates.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='scrolldown flexcenter'>
                        <div>
                            {/* <p className='mb-2'>Scroll Down</p> */}
                            <div className="flexcenter">
                                <img width='20px' className='arrowimage' src={scrolldownicon} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='sericespagecontain flexcenter'>
                <div className="container-fluid pt-5 pb-5">
                    <div className="container">
                        <h1 className='headtext text-center mb-5' data-aos="fade-up" data-aos-easing="ease-in-sine"
                            data-aos-duration="500">Delivery Services</h1>
                        <p style={{ textAlign: "justify" }} data-aos="fade-up" data-aos-easing="ease-in-sine"
                            data-aos-duration="800">
                            At Trident, we pride ourselves on offering a top-tier delivery service designed to meet your needs with precision and efficiency. Our team is committed to ensuring that every package, big or small, reaches its destination promptly and securely. With cutting-edge logistics technology and a network of skilled couriers, we handle everything from personal parcels to large-scale shipments with the utmost care and reliability. Our services are tailored to provide flexibility and convenience, whether you need same-day delivery or scheduled drops. Trust us to be your reliable partner in getting your deliveries where they need to go.
                        </p>
                    </div>
                </div>
            </section>

            <section className='sectionheight flexcenter'>
                <div className="container-fluid p-0">
                    <h1 className='headtext text-center mt-5 ms-5 me-5' data-aos="fade-up" data-aos-easing="ease-in-sine"
                        data-aos-duration="500">Why Choose Our Delivery Services?</h1>
                    <div className="container mt-5 flexcenter">
                        <div className="row w-100 flexcenter">
                            <div className="col-md-6">
                                <div>
                                    <div data-aos="fade-right" data-aos-easing="ease-in-sine"
                                        data-aos-duration="500">
                                        <div className="serviceftrctn flexcenter">
                                            <div className="row w-100">
                                                <div className="col-4 flexcenter">
                                                    <img width='200px' className='img-fluid' src={stopwatch} alt="" />
                                                </div>
                                                <div className="col-8 flexcenter">
                                                    <div className="container">
                                                        <h3>Timeliness</h3>
                                                        <p>We prioritize on-time delivery to meet your deadlines.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-aos="fade-right" data-aos-easing="ease-in-sine"
                                        data-aos-duration="800">
                                        <div className="serviceftrctn flexcenter">
                                            <div className="row w-100">
                                                <div className="col-4 flexcenter">
                                                    <img width='150px' className='img-fluid' src={security} alt="" />
                                                </div>
                                                <div className="col-8 flexcenter">
                                                    <div className="container">
                                                        <h3>Reliability</h3>
                                                        <p>Secure handling of all packages to ensure they arrive safely.</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="container">

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <div data-aos="fade-left" data-aos-easing="ease-in-sine"
                                        data-aos-duration="500">
                                        <div className="serviceftrctn flexcenter">
                                            <div className="row w-100">
                                                <div className="col-4 flexcenter">
                                                    <img width='200px' className='img-fluid' src={convenience} alt="" />
                                                </div>
                                                <div className="col-8 flexcenter">
                                                    <div className="container">
                                                        <h3>Convenience</h3>
                                                        <p>Easy booking process with flexible delivery options.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-aos="fade-left" data-aos-easing="ease-in-sine"
                                        data-aos-duration="800">
                                        <div className="serviceftrctn flexcenter">
                                            <div className="row w-100">
                                                <div className="col-4 flexcenter">
                                                    <img width='150px' className='img-fluid' src={navigation} alt="" />
                                                </div>
                                                <div className="col-8 flexcenter">
                                                    <div className="container">
                                                        <h3>Technology</h3>
                                                        <p>Advanced tracking systems for real-time updates.</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <div className="container">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>


        </>
    )
}

export default Deliverypage
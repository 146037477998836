import React from 'react'
import Formmsg from '../components/Formmsg'
// 
// import facebook from '../assets/facebook.svg'
// import instagram from '../assets/instagram.svg'
// import linkedin from '../assets/linkedin.svg'
import contactmsg from '../assets/contactmsg.svg'
import scrolldownicon from '../assets/scroll-down-icon.png'

function Contact() {
  return (
    <>
      <section className='contacttopsection flexcenter'>
        <div className="container-fluid  p-0">
          <div className="container">
            <h1 className="text-center" data-aos = "fade-up" data-aos-duration = "500" data-aos-easing="ease-in-sine">Be in Touch With Us</h1>
            <p className='text-justify mt-5' data-aos = "fade-up" data-aos-duration = "800" data-aos-easing="ease-in-sine">At Trident, we value your feedback and inquiries. Whether you have questions about our delivery, taxi, or tyre services, our team is here to assist you. Please feel free to reach out to us through the form below, call our customer support, or connect with us on our social media channels. We're committed to providing you with exceptional service and ensuring your experience with Trident is seamless and satisfactory.</p>
          </div>
          {/* <div className="socialmedia container flexcenter w-100">
            <img data-aos = "fade-up" data-aos-duration = "800" data-aos-easing="ease-in-sine" src={facebook} alt="" />
            <img data-aos = "fade-up" data-aos-duration = "1000" data-aos-easing="ease-in-sine" src={instagram} alt="" />
            <img data-aos = "fade-up" data-aos-duration = "1200" data-aos-easing="ease-in-sine" src={linkedin} alt="" />
          </div> */}
          <div className='scrolldown flexcenter'>
            <div>
              {/* <p className='mb-2'>Scroll Down</p> */}
             <div className="flexcenter">
             <img width='20px' className='arrowimage'  src={scrolldownicon} alt="" />
             </div>
            </div>
          </div>
        </div>
      </section>
      <section className='contactbtmsection homebgcolor flexcenter'>
        <div className="container-fluid flexcenter p-0">
          <div className="container flexcenter msgcontainer">
            <div className="row w-100">
              <div className="col-md-6 flexcenter" data-aos = "fade-right" data-aos-duration = "500" data-aos-easing="ease-in-sine">
                <img width='500px' src={contactmsg} className='img-fluid' alt="" />
              </div>
              <div className="col-md-6 flexcenter" data-aos = "fade-left" data-aos-duration = "500" data-aos-easing="ease-in-sine">
                <Formmsg></Formmsg>
              </div>
            </div>
          </div>
        </div>

      </section>

    </>
  )
}

export default Contact
import React from 'react'
// import facebook from '../assets/facebook.svg'
// import instagram from '../assets/instagram.svg'
// import linkedin from '../assets/linkedin.svg'

function Footer() {
    return (
        <>
            <section className='sitefooter flexcenter'>
                <div className="container-fluid p-0">
                    <div className="container pt-5">
                        <div className="row w-100">
                            <div className="col-md-4 mb-4">
                                <div className="container">
                                    <h5><b>Address:</b></h5>
                                    <p><b>Trident Transports and Logistics Ltd (TTL)</b>
                                        <br></br>
                                        128 Westfield Road <br />
                                        Birmingham <br />
                                        B14 7SU.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                {/* <div className="container">
                                    <h5 className='text-center mb-2'><b>Social Media Profiles</b></h5>
                                    <div className="socialmediafoot container flexcenter w-100">
                                        <img src={facebook} alt="" />
                                        <img src={instagram} alt="" />
                                        <img src={linkedin} alt="" />
                                    </div>
                                </div> */}
                            </div>
                            <div className="col-md-4 mb-4">
                                <div className="container">

                                    <h5 className='text-center mb-2'><b>Contact US</b></h5>
                                    <a href="tel:07763963881" style={{textDecoration : 'none',color : 'white'}}>
                                        <p className='text-center' >07763963881</p>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container copyrightcontain">
                        <p class="text-center copyrighttext"> Copyright © 2024-25 Trident Transports and Logistics Ltd (TTL) All rights reserved.</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer
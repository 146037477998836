import React, { useState } from 'react'
import axios from 'axios'
import { useFormik } from "formik";
import { formSchema } from '../validationSchema'
// import { useNavigate } from 'react-router-dom';
import { succesToaster,errorToaster } from './toaster';

function Formmsg() {
  console.log(process.env.REACT_APP_SECRET_KEY,"key")
  const [isloading, setisloading] = useState(false)
  const formik = useFormik({
    initialValues: {
      "name": "",
      "email": "",
      "phone": "",
      "message": ""
    },
    // process.env.REACT_APP_SECRET_KEY
    onSubmit: () => {
      setisloading(true)
      axios.post('https://tridentapi.demowebserver.in/contactus', formik.values,{
        headers : {
          api_key : process.env.REACT_APP_SECRET_KEY
        }
        // 'YxkbcXOSirMsNHvGZCoa' 
      }).then((response) => {
        succesToaster('success',`${formik.values.name}, thank you for getting in touch`)
        formik.resetForm()
        setisloading(false)
      }).catch((error) => {
        console.log(error)
        errorToaster('error','Message not sent')
        setisloading(false)
      })

    },

    validationSchema: formSchema
  })
  // console.log(formik.errors)
  return (
    <>
      <div className="container flexcenter">

        <div className="container formcontainer">
          <h1 className='text-center'>Send Us Message</h1>
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">Name</label>
              <input type="text" name="name" className={formik.errors.name && formik.touched.name ? "form-control is-invalid" : "form-control"} value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Enter your name" />
              {/* validation message*/}
              {formik.errors.name && formik.touched.name ? <p className='invalid-feedback'>{formik.errors.name}</p> : null}
            </div>


            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">Email address</label>
              <input type="text" name="email" className={formik.errors.email && formik.touched.email ? "form-control is-invalid" : "form-control"} value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Enter email address" />
              {/* validation message*/}
              {formik.errors.email && formik.touched.email ? <p className='invalid-feedback'>{formik.errors.email}</p> : null}
            </div>
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">Phone</label>
              <input type="text" name="phone" className={formik.errors.phone && formik.touched.phone ? "form-control is-invalid" : "form-control"} value={formik.values.phone} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Enter phone number" />
              {/* validation message*/}
              {formik.errors.phone && formik.touched.phone ? <p className='invalid-feedback'>{formik.errors.phone}</p> : null}
            </div>
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label"> Message</label>
              <textarea type="text" rows="4"
                cols="100" name="message" className={formik.errors.message && formik.touched.message ? "form-control is-invalid" : "form-control"} value={formik.values.message} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Enter message" />
              {/* validation message*/}
              {formik.errors.message && formik.touched.message ? <p className='invalid-feedback'>{formik.errors.message}</p> : null}
            </div>

            {/* {formik.isValid ? null : <span className="text-danger">Errors in some field</span> } */}
            <div class="container flexcenter">
              <button
                type="submit"
                // className={formik.isSubmitting && formik.errors ? "btn btn-primary disabled mt-4":"btn btn-primary mt-4"}
                className="button2 mt-4"
                disabled={formik.isSubmitting}

              >
                Submit
              </button>
            </div>
          </form>
          {isloading?<div className="loadercontain flexcenter">
            <div className="loader">

            </div>
          </div> : null}
          
        </div>


      </div>
    </>
  );
}

export default Formmsg;

import React from 'react'

function Services() {
  return (
    <>
      <div className="maincontainer">
        <h1>Services</h1>
      </div>
    </>
  )
}

export default Services
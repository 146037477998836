import Swal from "sweetalert2";

const SuccessToast = Swal.mixin({
    toast: true,
    animation: true,
    position: 'top-right',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
})


export function succesToaster(type, msg) {
    console.log('Toaster Working')
    SuccessToast.fire({
        icon: type,
        title: msg,
    })
}

export function errorToaster(type, msg) {
    console.log('Toaster Working')
    SuccessToast.fire({
        icon: type,
        title: msg,
    })
}
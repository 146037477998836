import React from 'react'
// 
import scrolldownicon from '../assets/scroll-down-icon.png'

// 
import allseason from '../assets/allseason.jpg'
import perfomance from '../assets/perfomance.webp'
import wintertyres from '../assets/wintertyres.jpg'
import offroadtyres from '../assets/offroadtyres.jpg'
import economytyre from '../assets/economytyre.jpg'
import tyretopimg from '../assets/tyretopimg.png'
import commitment from '../assets/commitment.png'
import brandimage from '../assets/brandimage.png'
import badge from '../assets/badge.png'
import widecollection from '../assets/widecollection.png'
import coin from '../assets/coin.png'
import convenience from '../assets/convenience.png'





function Tyrepage() {
  return (
    <>
      <section className='contacttopsection flexcenter'>
        <div className="container-fluid  p-0">
          <div className="container flexcenter">
            <div className="row w-100">
              <div className="col-md-6" data-aos="fade-right" data-aos-duration=" 500" data-aos-easing="ease-in-sine">
                <img className='img-fluid' width="700px" src={tyretopimg} alt="" />
              </div>
              <div className="col-md-6 flexcenter" data-aos="fade-left" data-aos-duration=" 500" data-aos-easing="ease-in-sine">
                <div className="container">
                  <h1>Tyre Sales</h1>
                  <p>We offer top-quality tyres from leading brands, including all-season, performance, winter, and off-road options. Our expert team provides tailored advice to help you choose the best tyres for your vehicle, driving habits, and budget.</p>
                </div>
              </div>
            </div>
          </div>
          <div className='scrolldown flexcenter'>
            <div>
              {/* <p className='mb-2'>Scroll Down</p> */}
              <div className="flexcenter">
                <img width='20px' className='arrowimage' src={scrolldownicon} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='sericespagecontain flexcenter'>
        <div className="container-fluid pt-5 pb-5">
          <div className="container">
            <h1 className='headtext text-center mb-5' data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine">Tyre Sales</h1>
            <p style={{ textAlign: "justify" }} data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in-sine">
              At Trident, we specialize in providing high-quality tyres to meet all your driving needs. Our extensive range of tyres includes products from leading brands known for their durability, performance, and safety. Whether you’re looking for tyres for your car, SUV, truck, or specialty vehicle, we have the right fit for you.
            </p>
          </div>
        </div>
      </section>

      <section className='sectionheight'>
        <div className="container-fluid p-0">
          <h1 className='headtext text-center mt-5' data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine">Why Choose Our Tyres?</h1>
          <div className="container mt-5 flexcenter">
            <div className="row w-100 flexcenter">
              <div className="col-md-6">
                <div>
                  <div data-aos="fade-right" data-aos-duration="500" data-aos-easing="ease-in-sine">
                    <div className="serviceftrctn flexcenter">
                      <div className="row w-100">
                        <div className="col-4 flexcenter">
                          <img width='200px' className='img-fluid' src={brandimage} alt="" />
                        </div>
                        <div className="col-8 flexcenter">
                          <div className="container">
                            <h3>Quality Brands</h3>
                            <p>We offer tyres from top manufacturers, ensuring reliability and superior performance.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-in-sine">
                    <div className="serviceftrctn flexcenter">
                      <div className="row w-100">
                        <div className="col-4 flexcenter">
                          <img width='150px' className='img-fluid' src={widecollection} alt="" />
                        </div>
                        <div className="col-8 flexcenter">
                          <div className="container">
                            <h3>Wide Selection</h3>
                            <p>From all-season and performance tyres to winter and off-road options, we have a diverse selection to suit various vehicles and driving conditions.</p>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="container">

                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <div  data-aos="fade-left" data-aos-duration="500" data-aos-easing="ease-in-sine">
                    <div className="serviceftrctn flexcenter">
                      <div className="row w-100">
                        <div className="col-4 flexcenter">
                          <img width='200px' className='img-fluid' src={badge} alt="" />
                        </div>
                        <div className="col-8 flexcenter">
                          <div className="container">
                            <h3>Expert Advice</h3>
                            <p>Our knowledgeable team can help you select the best tyres based on your vehicle type, driving habits, and budget.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div  data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-in-sine">
                    <div className="serviceftrctn flexcenter">
                      <div className="row w-100">
                        <div className="col-4 flexcenter">
                          <img width='150px' className='img-fluid' src={coin} alt="" />
                        </div>
                        <div className="col-8 flexcenter">
                          <div className="container">
                            <h3>Competitive Pricing</h3>
                            <p>We provide excellent value for money with competitive prices on all our tyre products.</p>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div data-aos="fade-right" data-aos-duration="1000" data-aos-easing="ease-in-sine">
                  <div className="serviceftrctn flexcenter">
                    <div className="row w-100">
                      <div className="col-4 flexcenter">
                        <img width='200px' className='img-fluid' src={convenience} alt="" />
                      </div>
                      <div className="col-8 flexcenter">
                        <div className="container">
                          <h3>Customer Satisfaction</h3>
                          <p>Our commitment to customer satisfaction means we stand by the quality of our tyres, ensuring you drive away with confidence.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">

              </div>
            </div>
          </div>
        </div>

      </section>
      {/* All-Season Tyres  */}
      <section>
        <h1 className='headtext text-center mt-5 mb-5'  data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine">Our Tyre Categories</h1>
        <div className="container-fluid p-0 flexcenter">

          <div className="container categorycard flexcenter">
            <div className="row">
              <div className="col-md-6 flexcenter"  data-aos="fade-right" data-aos-duration="500" data-aos-easing="ease-in-sine">
                <img
                  width="600px"
                  className="img-fluid"
                  src={allseason}
                  alt=""
                />
              </div>
              <div className="col-md-6 categorycardinside flexcenter" data-aos="fade-left" data-aos-duration="500" data-aos-easing="ease-in-sine">
                <div className="container homecardwrapper text-center">
                  <h1>All-Season Tyres</h1>

                  <p>Ideal for year-round use, providing a balance of performance, comfort, and durability.</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Performance Tyres */}
      <section>
        <div className="container-fluid p-0 flexcenter">
          <div className="container categorycard homecardflipped flexcenter">
            <div className="row reversecolumn">
              <div className="col-md-6 categorycardinside flexcenter" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-in-sine">
                <div className="container text-center">
                  <h1>Performance Tyres</h1>
                  <p>
                    Designed for enhanced handling and responsiveness, perfect for sports cars and high-performance vehicles.
                  </p>
                </div>
              </div>
              <div className="col-md-6 flexcenter" data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-in-sine">
                <img width="600px" className="img-fluid" src={perfomance} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Winter Tyres  */}
      <section>
        <div className="container-fluid p-0 flexcenter">
          <div className="container categorycard flexcenter">
            <div className="row">
              <div className="col-md-6 flexcenter" data-aos="fade-right" data-aos-duration="500" data-aos-easing="ease-in-sine">
                <img
                  width="600px"
                  className="img-fluid"
                  src={wintertyres}
                  alt=""
                />
              </div>
              <div className="col-md-6 categorycardinside flexcenter" data-aos="fade-left" data-aos-duration="500" data-aos-easing="ease-in-sine">
                <div className="container homecardwrapper text-center">
                  <h1>Winter Tyres</h1>

                  <p>Engineered to offer superior traction and control in snowy, icy, and cold conditions.</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Off-Road Tyres */}
      <section>
        <div className="container-fluid p-0 flexcenter">
          <div className="container categorycard homecardflipped flexcenter">
            <div className="row reversecolumn">
              <div className="col-md-6 categorycardinside flexcenter" data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-in-sine">
                <div className="container text-center">
                  <h1> Off-Road Tyres</h1>

                  <p>
                    Built to withstand rugged terrains, providing excellent grip and durability for off-road adventures.
                  </p>
                </div>
              </div>
              <div className="col-md-6 flexcenter" data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-in-sine">
                <img width="600px" className="img-fluid" src={offroadtyres} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Economy Tyres  */}
      <section>
        <div className="container-fluid p-0 flexcenter">
          <div className="container categorycard flexcenter">
            <div className="row">
              <div className="col-md-6 flexcenter" data-aos="fade-right" data-aos-duration="500" data-aos-easing="ease-in-sine">
                <img
                  width="600px"
                  className="img-fluid"
                  src={economytyre}
                  alt=""
                />
              </div>
              <div className="col-md-6 categorycardinside flexcenter" data-aos="fade-left" data-aos-duration="500" data-aos-easing="ease-in-sine">
                <div className="container homecardwrapper text-center">
                  <h1>Economy Tyres</h1>

                  <p>Budget-friendly options that deliver reliable performance and longevity.</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <h1 className='headtext text-center mt-5 mb-5' data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine">How to Choose the Right Tyres</h1>
        <div className="container-fluid flexcenter p-0">
          <div className="container" >
            <p className='text-center mb-4' style={{ textAlign: "justify" }} data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in-sine">
              Choosing the right tyres is crucial for safety, performance, and fuel efficiency. Here are some tips to help you make an informed decision:
            </p>
            <div className="container tyrebelowctn flexcenter" data-aos="fade-left" data-aos-duration="500" data-aos-easing="ease-in-sine">
              <div className="row w-100">
                <div className="col-md-4 text-center flexcenter">
                  <h5>Know Your Vehicle :</h5>
                </div>
                <div className="col-md-8">
                  <p className='mb-0' style={{ textAlign: "justify" }}>Check your vehicle’s manual for the recommended tyre size and specifications.</p>
                </div>
              </div>
            </div>
            <div className="container tyrebelowctn flexcenter" data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-in-sine">
              <div className="row w-100">
                <div className="col-md-4 text-center flexcenter">
                  <h5>Consider Your Driving Habits:</h5>
                </div>
                <div className="col-md-8">
                  <p className='mb-0' style={{ textAlign: "justify" }}>Think about the typical driving conditions you encounter, such as city driving, long-distance travel, or off-road adventures.
                  </p>
                </div>
              </div>
            </div>
            <div className="container tyrebelowctn flexcenter" data-aos="fade-left" data-aos-duration="1000" data-aos-easing="ease-in-sine">
              <div className="row w-100">
                <div className="col-md-4 text-center flexcenter">
                  <h5>Climate Matters:</h5>
                </div>
                <div className="col-md-8">
                  <p className='mb-0' style={{ textAlign: "justify" }}>Select tyres that are suitable for the weather conditions in your area. Winter tyres for snowy climates, and all-season tyres for moderate conditions.
                  </p>
                </div>
              </div>
            </div>
            <div className="container tyrebelowctn flexcenter" data-aos="fade-left" data-aos-duration="1200" data-aos-easing="ease-in-sine">
              <div className="row w-100">
                <div className="col-md-4 text-center flexcenter">
                  <h5>Budget:</h5>
                </div>
                <div className="col-md-8">
                  <p className='mb-0' style={{ textAlign: "justify" }}>Determine your budget but remember that investing in quality tyres can save you money in the long run by lasting longer and improving fuel efficiency.
                  </p>
                </div>
              </div>
            </div>
            <div className="container tyrebelowctn flexcenter" data-aos="fade-left" data-aos-duration="1400" data-aos-easing="ease-in-sine">
              <div className="row w-100">
                <div className="col-md-4 text-center flexcenter">
                  <h5>Tread Patterns: </h5>
                </div>
                <div className="col-md-8">
                  <p className='mb-0' style={{ textAlign: "justify" }}>Look at the tread patterns and choose one that suits your driving needs. For example, deeper treads are better for off-road, while symmetrical treads are ideal for regular highway driving.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <h1 className='headtext text-center mt-5' data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine">Our Commitment</h1>
        <div className="container-fluid p-0">
          <div className="container flexcenter">
            <div className="row w-100">
              <div className="col-md-6 flexcenter" data-aos="fade-right" data-aos-duration="500" data-aos-easing="ease-in-sine">
                <img className='img-fluid' width='500px' src={commitment} alt="" />
              </div>
              <div className="col-md-6 flexcenter" style={{ textAlign: "justify" }} data-aos="fade-left" data-aos-duration="500" data-aos-easing="ease-in-sine">
                <div><p>              At Trident, we are dedicated to ensuring that you find the perfect tyres for your vehicle. Our team is here to provide expert guidance and support throughout your tyre selection process. We understand that tyres are a critical component of your vehicle’s safety and performance, and we strive to offer only the best products and services to our customers.
                </p></div>
              </div>
            </div>
          </div>
        </div>
      </section>


    </>
  )
}

export default Tyrepage
import React from 'react'
// 
import scrolldownicon from '../assets/scroll-down-icon.png'
import taxipage from '../assets/taxipage.png'
import transport from '../assets/transport.png'
import driver from '../assets/driver.png'
import time from '../assets/24X7.png'
import booking from '../assets/booking.png'

function Taxipage() {
    return (
        <>
            <section className='contacttopsection flexcenter'>
                <div className="container-fluid  p-0">
                    <div className="container flexcenter">
                        <div className="row w-100">
                            <div className="col-md-6" data-aos="fade-right" data-aos-easing="ease-in-sine"
                                data-aos-duration="500">
                                <img className='img-fluid' width="600px" src={taxipage} alt="" />
                            </div>
                            <div className="col-md-6 flexcenter" data-aos="fade-left" data-aos-easing="ease-in-sine"
                                data-aos-duration="500">
                                <div className="container">
                                    <h1>Taxi Services</h1>
                                    <p>We offer comfortable rides in clean, well-maintained vehicles, driven by professional drivers who prioritize your safety and satisfaction, with 24/7 availability. Enjoy a seamless travel experience with our efficient taxi service.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='scrolldown flexcenter'>
                        <div>
                            {/* <p className='mb-2'>Scroll Down</p> */}
                            <div className="flexcenter">
                                <img width='20px' className='arrowimage' src={scrolldownicon} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='sericespagecontain flexcenter'>
                <div className="container-fluid pt-5 pb-5">
                    <div className="container">
                        <h1 className='headtext text-center mb-5' data-aos="fade-up" data-aos-easing="ease-in-sine"
                            data-aos-duration="500">Taxi Services</h1>
                        <p style={{ textAlign: "justify" }} data-aos="fade-up" data-aos-easing="ease-in-sine"
                            data-aos-duration="800">
                            Experience the ultimate in convenience and comfort with Tridents's taxi services. We offer a fleet of well-maintained vehicles, operated by professional and courteous drivers dedicated to providing a safe and pleasant journey. Whether you need a quick ride across town, an airport transfer, or transportation for a special event, we are available 24/7 to meet your needs. Our commitment to punctuality, safety, and customer satisfaction makes us the preferred choice for taxi services in the area.
                        </p>
                    </div>
                </div>
            </section>

            <section className='sectionheight flexcenter'>
                <div className="container-fluid p-0">
                    <h1 className='headtext text-center' data-aos="fade-up" data-aos-easing="ease-in-sine"
                        data-aos-duration="500">Our Taxi Service Features</h1>
                    <div className="container mt-5 flexcenter">
                        <div className="row w-100 flexcenter">
                            <div className="col-md-6">
                                <div>
                                    <div data-aos="fade-right" data-aos-easing="ease-in-sine"
                                        data-aos-duration="500">
                                        <div className="serviceftrctn flexcenter">
                                            <div className="row w-100">
                                                <div className="col-4 flexcenter">
                                                    <img width='200px' className='img-fluid' src={transport} alt="" />
                                                </div>
                                                <div className="col-8 flexcenter">
                                                    <div className="container">
                                                        <h3>Comfortable Rides</h3>
                                                        <p>Clean, well-maintained vehicles for a comfortable journey.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-aos="fade-right" data-aos-easing="ease-in-sine"
                                        data-aos-duration="800">
                                        <div className="serviceftrctn flexcenter">
                                            <div className="row w-100">
                                                <div className="col-4 flexcenter">
                                                    <img width='150px' className='img-fluid' src={driver} alt="" />
                                                </div>
                                                <div className="col-8 flexcenter">
                                                    <div className="container">
                                                        <h3>Professional Drivers</h3>
                                                        <p>Experienced drivers who prioritize your safety and satisfaction.</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="container">

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <div  data-aos="fade-left" data-aos-easing="ease-in-sine"
                                        data-aos-duration="500">
                                    <div className="serviceftrctn flexcenter">
                                        <div className="row w-100">
                                            <div className="col-4 flexcenter">
                                                <img width='200px' className='img-fluid' src={time} alt="" />
                                            </div>
                                            <div className="col-8 flexcenter">
                                                <div className="container">
                                                    <h3>24/7 Availability</h3>
                                                    <p>Ready to serve you any time of day or night.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div data-aos="fade-left" data-aos-easing="ease-in-sine"
                                        data-aos-duration="800">
                                    <div className="serviceftrctn flexcenter">
                                        <div className="row w-100">
                                            <div className="col-4 flexcenter">
                                                <img width='150px' className='img-fluid' src={booking} alt="" />
                                            </div>
                                            <div className="col-8 flexcenter">
                                                <div className="container">
                                                    <h3>Easy Booking</h3>
                                                    <p>Simple and efficient booking process, online or via phone.</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    </div>
                                </div>
                                <div className="container">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>


        </>
    )
}

export default Taxipage